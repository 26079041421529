import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getCategoryType } from 'src/app/common-ts/function-helper';
import { ICategoryGenre, ICategoryType } from 'src/app/models/categories';
import { ApiService } from '../api/api.service';

@Injectable({
    providedIn: 'root',
})
export class GenresService {
    private readonly url = 'genre';

    constructor(private apiService: ApiService) {}

    getAllTypes(): Observable<{ types: ICategoryType[], genres: ICategoryGenre[] }> {
        return this.apiService.getRequest(`${this.url}`)
            .pipe(map(({data}: {data: ICategoryGenre[]}) => ({ types: getCategoryType(data), genres: data })));
    }
}
